.search-form {
    display: flex;
    justify-content: center;
}

.search-input {
    width: 100%;
}

.search-button {
    margin-left: 5px;
    padding: 0 35px 0 35px;
}


.nav-search {
    

}

.nav-search-results {
padding-bottom: 4px;
}

.nav-results-container {
    position: absolute;
    background-color: white;
    width: 390px;
    padding: 5px;
    top: 70px;
}