.feed-card {
    background-color: #f3f0eb;
    padding: 15px;
    width: 500px;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 20px;
}

.card-text-area-feed {
    position: relative;
    // height: 120px;
}

.user-profile {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.feed-card-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    color: #818181;
}