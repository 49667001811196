.container {
    // height: 80vh;
    display: flex;
    align-items: center;
}

.form-container{
    // padding-bottom: 100px;
    width: 100%;
    margin: auto;

}

form {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: auto;
    // padding-left: 550px;
}

.labels {
    padding: 5px 0 5px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

input {
    margin: 10px 0 10px 0;
    border-radius: 5px;
    border: 1px solid lightgrey;
    padding: 10px;
    font-size: 16px;
}


input[type="file"] {
    border: none;
    width: 210px;
}

input[type=file]::file-selector-button {
    color: white;
    border: 1px solid #ED3D1E;
    padding: .2em .4em;
    border-radius: .2em;
    background-color: #ED3D1E;
    transition: 1s;
}


.primary-submit-button {
    color: white;
    font-size: 18px;;
    background-color: red;
    border: 0px;
    padding: 20px 10px;
}

.form-image-container {
    background-color: lightgrey;
    height: 340px;
    width: 520px;
    overflow: hidden;
    margin: auto;
}

.new-ramen-image {
    width:520px;
}

.hidden {
    display: none;
}

.signup-form-profile-banner-container {
    // position: relative;
    height: 300px;
    width: 500px;
    margin: auto;
}

.form-user-image-container {
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    background-color: lightgrey;
    width: 250px;
    height: 250px;
    left: 50%;
    top: 250px;
    transform: translate(-50%, -50%);
    border: white 5px solid;
}

.form-user-banner-image-container {
    position: absolute;
    overflow: hidden;
    border-radius: 5px;
    background-color: red;
    width: 450px;
    height: 150px;
    left: 50%;
    top: 170px;
    transform: translate(-50%, -50%);
}

// form {
//     padding-bottom: 100px;
// }

.quill {
    background-color: white;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 16px;
}

.ql-editor {
    min-height: 100px;
}

.ql-toolbar.ql-snow {
    border-radius: 5px 5px 0 0;
}

.ql-container {
    /* font-size: 16px !important; */
}

.login-form {
    padding-top: 100px;
}

@media only screen and (max-width: 600px) {
    .login-page-image{
        display: none;
    }
    
    form {
        display: flex;
        flex-direction: column;
        width: 400px;
        margin: auto;
        padding:0 0 280px 0;
    }

    .form-container {
        width: 400px;
        margin: auto;
        position: relative;
        padding-top: 40px;
    }

    .form-image-container {
        background-color: lightgrey;
        height: 340px;
        width: 400px;
        overflow: hidden;
        margin: auto;
    }

    .login-form {
        margin-top: 150px;
        padding: 0 0 0 0;
    }
}