.footer {
    // position: absolute;
    // bottom: 0;
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #ED3D1E;
}

.footer-column {
    display: flex;
    width: 100%;
    padding: 0 20px 0 20px;

}

.footer-column a {
    color: #ED3D1E;
}
