.container {
    display: flex;
    align-items: center;
}

.hero-banner {
    padding: 210px 0px 175px 0px;
    height: 100%;
    width: 1400px;
    margin: auto 0;
    display: flex;
    justify-content: space-around;
}

.hero-img {
    margin-top: -100px;
}

.hero-txt-area {
    padding: 0 0 20px 0;
}

.home-button {

}

.hero-img-desktop img {
    width: 400px;
}

.hero-blurb {
    width: 500px;
}

@media only screen and (max-width: 600px) {
    .hero-banner {
        padding: 0 0 0 0;
        // width: 100%;
        // margin: auto 0;
        margin-top: 120px;
        display: block;
        justify-content: space-around;
        align-items: center;
        }

        .hero-img {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .desktop {
            display: none;
        }
    
    .hero-img img {
        width: 300px;
        margin: auto 0;
    }

    .hero-txt {
        padding: 20px;
    }

    .hero-txt-area {
        padding: 20px;
    }

    .home-button {
        padding: 0 0 0 20px;
    }

    .hero-blurb {
        width: 400px;
    }
}   