.profile-card-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    width: 100vw;
    margin: auto;
    padding: 0 120px;
}

.profile-sections {
    display: flex;
}