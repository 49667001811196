.show-image-container {

}

.show-ramen-container {
    width: 1000px;
    margin: auto;
    padding: 20px 0 150px 0;
}

.show-page-img-ing {
    padding-right: 40px;
    padding-top: 50px;
}

.card-button-area-show {

}

.show-ramen {
    padding-top: 80px;
}

.ramen-recipe {
    display: flex;
    justify-content: space-around;
}

.uploaded-image {
    width: 520px;
}

@media only screen and (max-width: 600px) {
    .ramen-recipe {
        display: block;
    }

    .show-ramen {
        padding-top: 50px;
    }

    .show-ramen-container {
        width: 400px;
        margin: auto;
        padding: 20px;
    }

    .show-page-description {
        width: 400px;
    }

    .show-ramen-ing {
        width:400px;
    }

    .uploaded-image {
        width: 400px;
    }
}