.App {
  background-color: #EDE1CE;
  position: relative;
  min-height: 100vh;
  width: 100%;
}

h1, h2, h3, h4, p {
  color: #272624;
}

h1 {
  font-size: 44px;
  font-weight: 800;
}

h2 {
  font-size: 18px;
  font-weight: 200;
}

h3 {
  color: #ED3D1E;
}

h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 0px;
}

a {
  text-decoration: none;
}

.fade {
  transition: all 0.3s ease-in;
}

.fade-page {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.mobile {
  display: none;
}

.desktop {
  
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .mobile {
    display:contents;
  }

  .desktop {
    display: none;
  }
}