.navbar {
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
    width: 100vw;
    align-items: center;
}

.logged-in {
    background-color: #ED3D1E;
    /* position: fixed;
    width: 100%; */
}

.nav-left {
    display: flex;
    align-items: center;
}

.nav-logo {
    color: white;
    padding: 0 20px;
    font-size: 30px;
    font-weight: bold;
}

.nav-home {
    width: 100vw;
}

.navitems {
    color: #272624;
    text-decoration: none;
    display: flex;
    justify-content: right;
}

.nav-item {
    padding: 30px;
}

.item {
    color: #272624;
    text-decoration: none;
    
}

.nav-cta a {
    background-color: #ED3D1E;
    color:white;
    padding: 10px 15px;
}

Link {
    background-color: green;
}

.user-navitem {
    color:white;
    padding: 30px;
}

.nav-dropdown {
    display: none;
    position: absolute;
    top: 80px;
    right: 0px ;
    background-color: beige;
    z-index: 1;
}

.dropdown-item {
    padding: 20px;
}

.user-navitem:hover .nav-dropdown {
    display: block;
}

.item-in-dropdown {
    color: black;
    text-decoration: none;
}

// MOBILE NAVBAR //

.mobile-navbar {
    position: fixed;
    width: -webkit-fill-available;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background-color: #ED3D1E;
    color: white;
    font-size: x-large;
}

.mobile-menu-items {
    position: absolute;
    top:60px;
    left: 0;
    height: 100vh;
    background-color: #ED3D1E;
    width: 100%;
    z-index: 2;
}