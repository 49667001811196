.flex {
    display: flex;
    }

.flex-no-space {
    display: flex;
    justify-content: left;
}

.space-around {
    justify-content: space-around;
}

.space-between {
    justify-content: space-between;
}

.align-vert-center {
    align-items: center;
}