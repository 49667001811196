
.primary-button {
    color: white;
    text-decoration: none;
    background-color: #ED3D1E;
    padding: 15px 20px;
    cursor: pointer;
}

.primary-submit-button-grey {
    color: white;
    text-decoration: none;
    background-color: grey;
    padding: 15px 20px;
    cursor: pointer;
}

.delete-update-favourite-container {
    display: flex;
}

.account-button-area {
    display: flex;
}

.favourite-icon {
    color: #ED3D1E;
}

.secondary-button {
    color: white;
    text-decoration: none;
    background-color: #ED3D1E;
    padding: 10px 10px;
    margin-right: 10px;;
    cursor: pointer;
}

.tertiary-button {
    background-color: #ED3D1E;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
}