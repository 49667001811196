.show-user-container {
    width:100vw;
    overflow: hidden;
    text-align: center;
}

.show-user-image {
    position: absolute;
    top:200px;
    left: 80px;
    width: 250px;
    border-radius: 50%;
    border: 4px white solid;;
}

.header-image {
    width: 100vw;
}
.profile-header-image {
    height: 300px;
    overflow: hidden;
}

.profile-user-name {
    position: absolute;
    color: white;
    top: 250px;
    left: 360px;
}

.profile-user-name h1, a {
  color: white;
}
.profile-follow-buttons-area {
    display: flex;
    position: absolute;
    justify-content: space-between;
    width: 300px;
    top: 85px;
    left: 0;
}

.profile-menu-button {
    color: #ED3D1E;
    padding: 20px 30px;
    cursor: pointer;
}

.profile-menu {
    background-color: #ed3d1e22;
    width: 100%;
    display: flex;
    margin: auto;
    list-style: none;
    padding-inline-start: 330px;
}

.profile-content-container {
    width: 100vw;
    padding: 20px;
}